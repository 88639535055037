import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';
import {
  Button,
  Alert,
  Container,
  Navbar,
  Nav,
  NavDropdown
} from 'react-bootstrap'
import logo from '../../images/logo.png'



const Header = () => (
  <Navbar bg="light" expand="lg">
    <Container>
      <a href='/'>
        <img src={logo} className={styles.logoimage}>
        </img></a>
      <Navbar.Brand href="/">SkinO2 Esthetics</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          {/* <Nav.Link href="/aboutus">About Us</Nav.Link> */}
          <Nav.Link href="/services">Services</Nav.Link>
          <Nav.Link href="/contactus">Contact Us</Nav.Link>
          <Nav.Link href="/reviews">Reviews</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
