import React from 'react';
import PropTypes from 'prop-types';
import styles from './BusinessHoursComp.module.css';
import { Alert, Row, Col } from 'react-bootstrap';

const BusinessHoursComp = () => (
  <div className={styles.BusinessHoursComp}>
    <Row>
      <b style={{color: 'white'}}>Business Hours</b>
      <hr />
    </Row>
    {/* <Row>
      <Col>
        <Alert variant='primary'>
          <b>Sunday</b>
        </Alert>
      </Col>
      <Col>
        <Alert variant='primary'>
          9.00 AM - 5.00 PM
        </Alert>
      </Col>
    </Row> */}
    <Row>
      <Col>
        <Alert variant='primary'>
        <b>Monday</b>
        </Alert>
      </Col>
      <Col>
        <Alert variant='primary'>
          9.00 AM - 5.00 PM
        </Alert>
      </Col>
    </Row>
    <Row>
      <Col>
        <Alert variant='primary'>
          <b>Tuesday</b>
        </Alert>
      </Col>
      <Col>
        <Alert variant='primary'>
          9.00 AM - 5.00 PM
        </Alert>
      </Col>
    </Row>
    <Row>
      <Col>
        <Alert variant='primary'>
          <b>Wednesday</b>
        </Alert>
      </Col>
      <Col>
        <Alert variant='primary'>
          9.00 AM - 5.00 PM
        </Alert>
      </Col>
    </Row>
    <Row>
      <Col>
        <Alert variant='primary'>
          <b>Thursday</b>
        </Alert>
      </Col>
      <Col>
        <Alert variant='primary'>
          9.00 AM - 5.00 PM
        </Alert>
      </Col>
    </Row>
    <Row>
      <Col>
        <Alert variant='primary'>
          <b>Friday</b>
        </Alert>
      </Col>
      <Col>
        <Alert variant='primary'>
          9.00 AM - 5.00 PM
        </Alert>
      </Col>
    </Row>
    {/* <Row>
      <Col>
        <Alert variant='primary'>
          <b>Saturday</b>
        </Alert>
      </Col>
      <Col>
        <Alert variant='primary'>
          9.00 AM - 1.00 PM
        </Alert>
      </Col>
    </Row> */}
    
  </div>
);

BusinessHoursComp.propTypes = {};

BusinessHoursComp.defaultProps = {};

export default BusinessHoursComp;
