import React from 'react';
import PropTypes from 'prop-types';
import styles from './Reviews.module.css';
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  Alert

} from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from '../../Lotties/Review.json';
import googlereviews from '../../images/GoogleReviews.png';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Reviews = (props) => (


  <div className={styles.Reviews}>
    <Container>
      {/* <Row style={{ textAlign: 'center' }}>
        <Col style={{ margin: '2rem' }}>
          <Alert variant="primary">
            <Alert.Heading>We would love to hear back from you!</Alert.Heading>
            <hr />
          </Alert>
        </Col>
      </Row> */}
      <Row>
        <Col style={{textAlign: 'center'}}>
          <br />
          <Alert variant="primary">
            <Alert.Heading>We would love to hear back from you!</Alert.Heading>
            <hr />
          </Alert>
          <Alert variant="light">
            <Alert.Heading></Alert.Heading>
            <Row>
              <Col style={{ textAlign: 'center' }}>
                <Lottie options={defaultOptions}
                  height={300}
                  width={300} />
                <Button variant='warning' onClick={props.onClickHandler}>
                  Click here to provide your feedback
                </Button>
              </Col>
              <Col style={{ textAlign: 'center', paddingTop: '5rem' }}>
                <Image style={{ height: '200px' }} src={googlereviews}></Image>
                <div style={{paddingTop: '1.5rem'}}>
                  <Button variant='warning'>
                    Scan above code if you prefer to talk about us on Google reviews
                  </Button></div>
              </Col>
            </Row>
            <hr />
          </Alert>
        </Col>
      </Row>
    </Container>


  </div>
);

Reviews.propTypes = {};

Reviews.defaultProps = {};

export default Reviews;
