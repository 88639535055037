import React from 'react';
import PropTypes from 'prop-types';
import styles from './Specials.module.css';
import ValentineDayOffer from '../ValentineDayOffer/ValentineDayOffer';
import WomansDayOffer from '../WomansDayOffer/WomansDayOffer';
import SpringSpecial from '../SpringSpecial/SpringSpecial';
import MothersDayComp from '../MothersDayComp/MothersDayComp';
import { Container } from 'react-bootstrap';
import FathersDayComp from '../FathersDayComp/FathersDayComp';
import IndependenceDayComp from '../IndependenceDayComp/IndependenceDayComp';
import GeneralDiscountComp from '../GeneralDiscountComp/GeneralDiscountComp';
import BckToSchoolComp from '../BckToSchoolComp/BckToSchoolComp';


const Specials = () => (
   <Container>
      {/* <ValentineDayOffer /> */}
      {/* <WomansDayOffer /> */}
      {/* <SpringSpecial /> */}
      {/* <MothersDayComp /> */}
      {/* <FathersDayComp /> */}
      {/* <IndependenceDayComp /> */}
      <GeneralDiscountComp />
      {/* <BckToSchoolComp /> */}
   </Container>
);

Specials.propTypes = {};

Specials.defaultProps = {};

export default Specials;
