import React from 'react';
import PropTypes from 'prop-types';
import styles from './MothersDayComp.module.css';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Nav
} from 'react-bootstrap';
import spring from '../../images/mothersday.jpg'

const redirectHandler = (data) => {
  window.open('https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVJ/sJqo5GNbuORPk3RPnhYWfJ6cwVM2PXUtnzxDQBXPCIYP+okZs6vUaY8KPzPtZLsPXLvFB/NXBaqgI0gqZwmQPPMyUS3KAoN+IzLTQHUmCGJx/i5KSKt3NtplhhbgWu7z+wAlCI3FSDNG5G5Y5Lowa2eipmcz89lSGgPkhRuC7pqyvBMcS93FVAfDY+FN+IAyOTxr7cXuu8WRRDkaBb3tY8LwM9n2qv4ByiTdJxKS0qGUXctuL/OFDemRgrLpLoSZf9lZXdPyLsEJLzPNKdD4deQN0HR9Gwdb0ufZv/wUXcdEyJAvAwiv6NqSecJpiRA==');
}

const MothersDayComp = () => (
  <div className={styles.MothersDayComp}>
    <Container fluid>
      <Row className={styles.rowClass}>
        <Col className={styles.colClass}>
          <div style={{ top: '-20vh', fontFamily: 'Papyrus', margin: '2vw' }}>
            {/* <h1>Celebrating Spring 2022</h1><hr /> */}
            <Image src={spring} style={{ width: '90%' }}></Image>
          </div>

          <div className={styles.customContent}>
            <h1>Happy Mother's May</h1>
            <hr />
          </div>
          <div>
            <h2>20% off any facial</h2>
            <p>Offer valid through entire May!!</p>
          </div>
          <Button style={{ width: '100%' }} onClick={redirectHandler.bind(this)} variant="dark">Book My Appointment</Button>
          <hr />
        </Col>
      </Row>
    </Container>
  </div>
);

MothersDayComp.propTypes = {};

MothersDayComp.defaultProps = {};

export default MothersDayComp;
