import React from 'react';
import PropTypes from 'prop-types';
import styles from './MyServices.module.css';
import { Card, Button } from 'react-bootstrap'

const redirectHandler = (data) => {
    data.onclickhandler();
}

const MyServices = (props) => (
 
  <div className={styles.MyServices}>
    <Card style={{'minHeight': props.minht, 'minWidth': '300px', background: props.bgclr}} className={styles.MyServiceCard} bg={props.bgclr} text={props.txtclr}>
      <Card.Body>
        <Card.Title>{props.servicename}</Card.Title>
        <Card.Text>
          <b>{props.charges}</b>
          <br />
          {props.description}
        </Card.Text>
        <Card.Footer style={{'position': 'absolute', bottom: '2px'}}>
          <Button onClick={redirectHandler.bind(this, props)} variant="light">Book Now</Button>
        </Card.Footer>
      </Card.Body>
    </Card>
  </div>
);

MyServices.propTypes = {};

MyServices.defaultProps = {};

export default MyServices;
