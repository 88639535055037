import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Reviews from '../../components/Reviews/Reviews';

const ReviewPage = () => {
    const LoadReviewPage = () => {
        window.open(`https://www.vagaro.com/Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVJ/sJqo5GNbuORPk3RPnhYWfJ6cwVM2PXUtnzxDQBXPCIYP+okZs6vUaY8KPzPtZLsPXLvFB/NXBaqgI0gqZwmRfl3SuqUOeatoeOL8qL1VDkPgqK3SPU4FEmDWHo7affXa5sN990GoBuUhwXJRuKbGbj1xWAEtjdG5/QnoGenDQh/cXZNAvwwvPz6C4AWkq/1zX1p1pNZJYWLAT+AN7zRRROoaYr/e71yiG9NWjsTDQsQrKX4ms7zWZHpwDw4/NpvtYof+3kyIGAeMvusSGJmPaxOhf6WkqtOeTDnXRDiq8qeGrBqil2qbvc0OMo1OaxQ==`);
    }

   

    return (
        <div>
             <Header />
            <Reviews onClickHandler={LoadReviewPage} />
            <Footer />
        </div>
    );
}

export default ReviewPage