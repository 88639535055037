import React from 'react';
import PropTypes from 'prop-types';
import styles from './Announcement.module.css';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const Announcement = (props) => (

  <div className={styles.Announcement}>
    <div className={styles.firework}></div>
    <div className={styles.firework}></div>
    <div className={styles.firework}></div>
    <div className={styles.firework}></div>
    <div className={styles.firework}></div>
    <div className={styles.AnnouncementText}>
      <p>
        <h2>Hooray! We are Expanding!!</h2>
      </p>
      <hr />
      <p>
        See you at
      </p>
      <p>
        <b>
          8331 Gunn Highway Westchase, Tampa 33626
        </b>
      </p>
      <p>
        <i>on Thursday, Friday and Saturday from 9 am to 5 pm</i>
      </p>
    </div>
    {/* <div className={styles.fireworks}>
      <h1>Hello</h1>
    </div> */}
    {/* <Alert show={props.show} variant="primary">
          <Alert.Heading>Hooray!! We Are Expanding!!</Alert.Heading>
          <p>
            See you at <b>8331 Gunn Highway Westchase, Tampa 33626</b> on Thursday, Friday and Saturday from 9 am to 5 pm
          </p>
          <hr />
        </Alert> 
       */}
  </div>
);

Announcement.propTypes = {};

Announcement.defaultProps = {};

export default Announcement;
