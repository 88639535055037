import React from 'react';
import ContactUs from '../../components/ContactUs/ContactUs';
import Footer from '../../components/Footer/Footer';
import GoogleMapComp from '../../components/GoogleMapComp/GoogleMapComp';
import Header from '../../components/Header/Header';
import './ContactUs-Style.css'

const ContactUsPage = () => {
    return (
        <div className='contactUsDiv'>
             <Header></Header>
            <ContactUs />
            <Footer />
        </div>
    );
}

export default ContactUsPage