import React from 'react';
import PropTypes from 'prop-types';
import styles from './ValentineDayOffer.module.css';
import { 
  Container, 
  Row, 
  Col,
  Image
} from 'react-bootstrap';
import vday from '../../images/vday.jpg';
import hearts from '../../images/hearts.png';

const ValentineDayOffer = () => (
  <div className={styles.Specials}>
  <Container fluid>
    <Row className={styles.rowClass}>
      <Col>
        <Image className={styles.vdayimageClass} src={vday}></Image>
        <div style={{fontFamily: 'Papyrus', margin: '2vw'}}><h1>Check Our Specials!!</h1><hr /></div>
        {/* <div className={styles.specialContainer}>
           <div className={styles.Title}>Expecting</div>
           <div className={styles.content}>30 min Prenatal Massage & 30 min facial ($120)</div>
        </div> */}
        <div className={styles.specialContainer}>
           <div className={styles.Title}>Refresh</div>
           <div className={styles.content}>30 min relaxation back massage & 60 min facial ($120)</div>
        </div>
        <div className={styles.specialContainer}>
           <div className={styles.Title}>Relax Rewind & Go</div>
           <div className={styles.content}>30 min Hand Exfoliation treatment & 60 min facial & Eyebrow threading ($130)</div>
        </div>
        <div className={styles.specialContainer}>
           <div className={styles.Title}>Revive</div>
           <div className={styles.content}>Signature Facial with  dermaplaning & 20min foot Massage ($140)</div>
        </div>
        {/* <div className={styles.specialContainer}>
           <div className={styles.Title}>Sparkling Specials!!</div>
           <div className={styles.content}>60 min Deep Tissue Massage, 30 min Casmara Mask Facial & Take Home Product!! ($190)</div>
        </div> */}
        <div className={styles.specialContainer}>
           <div className={styles.Title}>Hugs & Kisses</div>
           <div className={styles.content}>Buy your Facial & Book a 50 min Facial for your spouse ($150)</div>
        </div>
        <div className={styles.specialContainer}>
           <div className={styles.Title}>Princess For an Hour</div>
           <div className={styles.content}>Facial with Casmara mask & take home product ($170)</div>
        </div>
        <div className={styles.specialContainer}>
           <div className={styles.Title}>Take Two</div>
           <div className={styles.content}>Buy 2 Vital C facials & take 3rd facial for half a price ($220)</div>
        </div>
      </Col>
    </Row>
  </Container>
</div>
);

ValentineDayOffer.propTypes = {};

ValentineDayOffer.defaultProps = {};

export default ValentineDayOffer;
