import React from 'react';
import PropTypes from 'prop-types';
import styles from './Aboutus.module.css';
import { Alert, Col, Row, Image, Container } from 'react-bootstrap';
import MayuriImage from '../../images/Mayuri-4.png';


const Aboutus = () => (
  <div className={styles.Aboutus}>
    <Container className={styles.container}>
      <Row>
        <Col style={{width: '100%'}}>
          <br />
          <br />
          <Image roundedCircle style={{width: '30%'}}
            className={styles.ownerImage}
            src={MayuriImage}>
          </Image>
          <div className={styles.contentDiv}>
            {/* <h1>Welcome To SkinO2 Esthetics!!</h1> */}
            <h1 style={{textAlign: 'center'}}>A Warm Welcome From Mayuri!!</h1>
            <br />
            <br />
            <p>
              Hello!! <br />
              My name is Mayuri and I am a licensed Esthetician. My passion and purpose is to help everyone
              to find their confidence from the inside out.
            </p>
            <p>
              At SkinO2 Esthetics, I offer a wide range of services and products to both female and male
              clients to make permanent changes to your skin.
            </p>
            <p>
              I help you to look beautiful by providing unique combination of all Facial treatments with
              best customer service in relaxing and rejuvenating atmosphere
            </p>
          </div>
        </Col>
      </Row>
    </Container>

  </div>
);

Aboutus.propTypes = {};

Aboutus.defaultProps = {};

export default Aboutus;
