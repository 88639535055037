import React from 'react';
import PropTypes from 'prop-types';
import styles from './Carouselimages.module.css';
import {
  Carousel,
  Button, 
  Nav
} from 'react-bootstrap'
import firstSlide from '../../images/first-image.jpeg';
// Credits - <a href="https://www.freepik.com/photos/woman">Woman photo created by pressfoto - www.freepik.com</a>
import secondSlide from '../../images/second-image.png';
import thirdSlide from '../../images/third-image.png';



const Carouselimages = () => (
  <Carousel className='.carousel-background'>
    <Carousel.Item>
      <img className="d-block w-100"
        src={firstSlide}
        alt="First slide"
      />
      <Carousel.Caption>
        {/* <h3>Recover. Rejuvenate. Revive!</h3>
        <p>Progressive styling in a nurturing environment.</p> */}
          
          {/* <Nav.Link href="/services">
            <Button variant="primary">Explore Services</Button>
          </Nav.Link> */}
       
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img className="d-block w-100"
        src={secondSlide}
        alt="Second slide"
      />
      <Carousel.Caption>
        {/* <h3>Be welcomed. Be pampered. Be yourself.</h3>
        <p>Luxury salon where you will feel unique and special.</p> */}
        {/* <Nav.Link href="/services">
            <Button variant="primary">Explore Services</Button>
          </Nav.Link>
        */}
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img className="d-block w-100"
        src={thirdSlide}
        alt="Third slide"
      />
      <Carousel.Caption>
      {/* <a href='https://www.freepik.com/photos/woman'>Woman photo created by Racool_studio - www.freepik.com</a> */}
        {/* <h3>Everyone is beautiful, we just make it obvious!</h3>
        <p>
        Relaxation. No longer beyond your budget.
        Revitalize your beauty, Revitalize your soul.
        </p> */}
        {/* <Nav.Link href="/services">
            <Button variant="primary">Explore Services</Button>
          </Nav.Link> */}
       
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
);

Carouselimages.propTypes = {};

Carouselimages.defaultProps = {};

export default Carouselimages;
