import React from 'react';
import PropTypes from 'prop-types';
import styles from './GeneralDiscountComp.module.css';
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Image
} from 'react-bootstrap';

// import girlpic from '../../images/discount-girl.png';
import pic from '../../images/Festival.jpg';

const redirectHandler = (data) => {
  window.open('https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVJ/sJqo5GNbuORPk3RPnhYWfJ6cwVM2PXUtnzxDQBXPCIYP+okZs6vUaY8KPzPtZLsPXLvFB/NXBaqgI0gqZwmQPPMyUS3KAoN+IzLTQHUmCGJx/i5KSKt3NtplhhbgWu7z+wAlCI3FSDNG5G5Y5Lowa2eipmcz89lSGgPkhRuC7pqyvBMcS93FVAfDY+FN+IAyOTxr7cXuu8WRRDkaBb3tY8LwM9n2qv4ByiTdJxKS0qGUXctuL/OFDemRgrLpLoSZf9lZXdPyLsEJLzPNKdD4deQN0HR9Gwdb0ufZv/wUXcdEyJAvAwiv6NqSecJpiRA==');
}

const GeneralDiscountComp = () => (
  <Container fluid>
    <Row className={styles.rowClass} style={{ background: "black", color: 'white' }}>
      <Col>
        <Image src={pic} style={{ width: '100%' }}></Image>
        <Row>
          <Col className={styles.colClass}>
            <div style={{ position: 'relative' }}>
              {/* <h1>Get 15% Discount on your first facial!!</h1> */}
              <Row>
                <Col>
                  <h1>For a limited time, save 10% on any facial!!</h1>
                  <br />
                  <br />
                  <h4>
                    <p>
                      Treat yourself to the perfect facial, customized for your unique skin needs.
                      <br /><br />
                      Don't miss this chance to your facial with an expert esthetician in Odessa!
                    </p>
                  </h4>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>

    <Row>
      <Button style={{ width: '100%' }} onClick={redirectHandler.bind(this)} variant="dark">Book My Appointment</Button>
    </Row>

  </Container>

);

GeneralDiscountComp.propTypes = {};

GeneralDiscountComp.defaultProps = {};

export default GeneralDiscountComp;
