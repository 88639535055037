import React from 'react';
import './Aboutus-style.css'
import Aboutus from '../../components/Aboutus/Aboutus';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {
    Row,
    Col
} from 'react-bootstrap';

const AboutUsPage = () => {
    return (
        <div>
            <Row>
                <Col>
                    <Header></Header>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Aboutus></Aboutus>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Footer />
                </Col>
            </Row>


        </div>
    );
}

export default AboutUsPage