import React from 'react';
import MyServices from '../../components/MyServices/MyServices';
import {
    Row,
    Col,
    Container,
    Accordion
} from 'react-bootstrap'
import Header from '../../components/Header/Header';
import Lottie from 'react-lottie';
import animationData from '../../Lotties/beauty-parlour.json';
import { useNavigate } from 'react-router';
import Footer from '../../components/Footer/Footer';
import './ServicesPage-Style.css';


const ServicesPage = () => {

    const skincarebgColor = "#babade";
    const skinboosterbgColor = "#dc7c71";
    const eyeLashbgColor = "#89c5ba";
    const threadingbgColor = "#a9b7c0";
    const waxingbgColor = "#e1cee5";

    const navigate = useNavigate()

    const redirectToSquare = () => {
        //navigate('/booking')
        //window.open('https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVJ/sJqo5GNbuORPk3RPnhYWfJ6cwVM2PXUtnzxDQBXPCIYP+okZs6vUaY8KPzPtZLsPXLvFB/NXBaqgI0gqZwmQPPMyUS3KAoN+IzLTQHUmCGJx/i5KSKt3NtplhhbgWu7z+wAlCI3FSDNG5G5Y5Lowa2eipmcz89lSGgPkhRuC7pqyvBMcS93FVAfDY+FN+IAyOTxr7cXuu8WRRDkaBb3tY8LwM9n2qv4ByiTdJxKS0qGUXctuL/OFDemRgrLpLoSZf9lZXdPyLsEJLzPNKdD4deQN0HR9Gwdb0ufZv/wUXcdEyJAvAwiv6NqSecJpiRA==');
        //New URL 
        window.open('https://www.vagaro.com/OnlineChooseBusiness.aspx?enc=FeWMMfY+7xoz0VTrhiAKp1We0HsZXRFa/Mt6arUeZhHa2QaI5/PE84LanS0c8g3t/9sxDbrrtUfK8GhUZKO6AdUfk2lbX3BZzM2C/qL/zss8jazmhU+IsALY3hYxA1jghpMe7g/fG/rB2HlGG9WqHxahiYsJo9UuLVUpGX+dp8D5NsGxU3ejDKfzzJZtfUnVSkgOdma6t63b/K/85ebN9x378XPFJ8bb0XuYXt+wlCaciYHMwqyFKguOOz4HxZpHoF8UL0KK9ea6kKYUgFPbX5gkubL46QZ5NTuubn6jg9+10s3lLAQVEloCuQ5bIe3ktHBuS5g4Aj9SJ6cwN6PslupIA7QhH85UZwrnkkxDLOc1vPolxoYhxPOFeN0xZQ2IE0Th8kgJBWnMX0a1AB/EBSv8XrZhSbr28LTNfRfvlGgow+BsT4HyTVplfV0HbIgKLb/AnTaZoucBaMHVAcjxlSvdJ6tuT47OkbP6aPpyRWDfc1DUvibCkeT2J7VSPTP07w7LArD16Yu4XjQbpGVgtxdE/+4RMYL750c8RUTNFTH/VwbWgYfLv9q6tXjnJtJLD7R57zIe06LtID/CkqprjYfww4JOINd6ucoiDg/dnT0u4FQVaV3PWVPA4YxrT+iU2XLo2kzMqoCblQ7zm+hYSgv/oMaaCSyLfRyvTzZ+QoPTV23xijglcxYwPZPyjFqz708NITskna98ePMwz97LBnMrpCHOcWf8GF7cfdt2Yr0=');
        
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const getCustomFacialText = () => {
        return (
            <span>
                The customized facial includes cleansing, steam, skin analysis, customized exfoliation, extractions (as needed), and relaxing massage (face and arms). Advanced techniques like -

                <b><ul>
                    <li>Dermaplane</li>
                    <li>Microdermabrasion</li>
                    <li>Enzyme Chemical peel</li>
                    <li>High Frequency</li>
                    <li>Micro-current</li>
                </ul></b>
                can be added depending on your skin requirements. After this facial, you can expect brightened and more healthy skin. We also guide you on the home care skin routine to keep your skin glowing
            </span>)
    }

    return (
        <Container fluid className="containerStyle">
            <Header />
            <Row>
                <Col>
                    <Lottie options={defaultOptions}
                        height={400}
                        width={400} />

                </Col>
                <Col>
                    <br />
                    <h1>Give your skin a gift of renewed radiance.</h1>
                    <hr />
                    <p>
                        Our cleansing, rejuvenating treatment nourishes, hydrates, soothes and tones,
                        leaving your skin feeling soft, glowing, and refreshed. We customize the
                        treatment for your skin type and take extra care with sensitive skin.
                        Skin is cleansed, analyzed, exfoliated under steam, then any impurities are
                        extracted. Once prepared, you are treated to a facial massage, mask and
                        moisturizer, all custom-tailored to your skin’s specific needs.
                    </p>
                    <p>
                        <b>Appointment Cancellation Policy</b>
                        <br />
                        <i>As a courtesy, please remember to call us as soon as you know that you
                            will not be able to make your scheduled appointment and we'd be happy
                            to rebook it for you. If you miss an appointment or fail to give us 24 hour notice
                            you will be charged a cancellation fees of $40</i>
                    </p>
                </Col>
            </Row>
            <Row>
                <h1>Services Offered</h1>
                <hr />
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><b>Skincare</b></Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col>
                                    <MyServices servicename='Regular Facial' charges='$70 (60 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='550px'
                                        onclickhandler={redirectToSquare}
                                        description='Extraction, Exfoliation,rejuvanation and relaxation '>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Express Facial' charges='$55 (45 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='550px'
                                        onclickhandler={redirectToSquare}
                                        description='This express skin refresher consists of a cleansing, toning, exfoliation, light extraction, mask and moisturizer. This service is great if you are looking to resurface and rejuvenate your skin'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Custom Facial' charges='$120 (90 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='550px'
                                        onclickhandler={redirectToSquare}
                                        description={getCustomFacialText()}>

                                    </MyServices>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MyServices servicename='Back treatment with Microdermabrasion' charges='$120 (60 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='350px'
                                        onclickhandler={redirectToSquare}
                                        description='A back facial is a treatment that consists of deep cleansing, exfoliation (with the help of microdermabrasion) and extractions. It is performed on your back. In a back facial, I will use creams for massage and  steam to exfoliate and clean your skin before extracting oil and debris. The treatment ends with a detoxifying mask for hydration.'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Teen Facial' charges='$55 (50 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='350px'
                                        onclickhandler={redirectToSquare}
                                        description='Facials for teens are often designed to specifically address breakouts that frequently are associated with adolescent skin. Teen facials begin with a deep cleansing, followed by an enzyme mask with steam and gentle extractions. A healing mask will then help to rehydrate dry, flaky skin, or eliminate oily particles.'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename="The Gentleman's Facial" charges='$75 (50 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='350px'
                                        onclickhandler={redirectToSquare}
                                        description='The Gentleman’s Facial is tailored for a man’s specific skin care needs. The treatment starts with a deep cleansing, followed by an exfoliating scrub and extraction. To help replenish the skin, the facial concludes with a cool, soothing algae mask used to balance and hydrate your skin, also helping alleviate shaving irritation.'>

                                    </MyServices>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col>
                                    <MyServices servicename='Regular Facial' charges='$70 (60 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='Extraction, Exfoliation,rejuvanation and relaxation '>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Anti Aging/Microcurrent Facial' charges='$120 (80 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='Bye Bye Wrinkles and sagging skin. Microcurrent improves tighten and smooth the muscles and connectivity tissues in the face by increasing cellular activity and have been shown to reduce wrinkles'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Sparkle Signature Facial' charges='$110 (70 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='In this procedure exfoliates and removes the superficial layer of dry 
                                    , dead skin cells with use of Microdermabrasion. It buffs and polishes the skin using 
                                    a stream of a diamond tip. A vacuum is then administered to loosen any dead skin and clogged
                                    pores and remove it from the face. Perfect for guests with uneven pigmentation, clogged
                                    pores, problematic skin and those who would like a smoother complexion. Can be added to a 
                                    facial or as a standalone service'>
                                    </MyServices>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MyServices servicename='Advance Exfoliation-Vital C (Chemical Peel)' charges='$110 (90 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='Looking for revitalized skin? Try our nutrient-packed facial, which combine effective lighteners and brighteners to target the skin pigment and create a bright luminous complexion. Among these, our Vitamin C Brightening Facial is the most helpful.
                                        For an additional boost to your skin, we recommend trying our facial chemical peel as well.
                                        A resurfacing treatment perfect for sensitive skin, designed for age management and hyperpigmentation.'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Advance Exfoliation-Hydrating Facial' charges='$80 (60 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='For deep nourishment, try our Dry/Dehydrated Restorative Facial which is the ultimate treatment for skin in need of moisture. Using antioxidant-rich conditioning ingredients, this treatment helps restore skin strength and elasticity while creating incredibly soft skin. This improves the overall health of your skin.'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Enzyme Facial' charges='$80 (60 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='Enzyme Therapy is a facial treatment that stimulates your capillaries by dilating the channels to allow fresh oxygen & blood flow to the face. It exfoliates dead skin cells into a weak acid & flushes them from the skin.'>

                                    </MyServices>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MyServices servicename='Acne Facial' charges='$90 (60 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='Be spotless…In this treatment cleansing the skin to remove impurities,debris & oil unclogging pores.Using exfoliation which is the removal of dead skin cells and reducing oil production and imflamation of the skin'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Dermaplaning Facial' charges='$100 (60 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='Process involves using a medical grade tools to remove dead skin cells and remove peach fuzz.

                                        Wonderful prep for better absorption of skincare and other facial treatments.
                                        
                                        This facial will remove the top layer of dead skin cells and all facial hairs .Dermaplaning will provide an effective and safe exfoliation treatment that promotes deeper product penetration,boosting the effect of skin care products while making the skin look and feel smoother.'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Teen Facial' charges='$55 (50 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='Facials for teens are often designed to specifically address breakouts that frequently are associated with adolescent skin. Teen facials begin with a deep cleansing, followed by an enzyme mask with steam and gentle extractions. A healing mask will then help to rehydrate dry, flaky skin, or eliminate oily particles.'>

                                    </MyServices>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <MyServices servicename='Express Facial' charges='$55 (45 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='This express skin refresher consists of a cleansing, toning, exfoliation, light extraction, mask and moisturizer. This service is great if you are looking to resurface and rejuvenate your skin'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename="The Gentleman's Facial" charges='$75 (50 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='The Gentleman’s Facial is tailored for a man’s specific skin care needs. The treatment starts with a deep cleansing, followed by an exfoliating scrub and extraction. To help replenish the skin, the facial concludes with a cool, soothing algae mask used to balance and hydrate your skin, also helping alleviate shaving irritation.'>

                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Relaxing Back Facial' charges='$70 (45 min)'
                                        bgclr={skincarebgColor} txtclr='dark' minht='400px'
                                        onclickhandler={redirectToSquare}
                                        description='In this treatment we  will exfoliate and extraction then massage and in the end of the service will apply mask to rejuvenation and hydration.

                                        This will for ingrown and acne prone skin.'>

                                    </MyServices>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Col>
                                        <MyServices servicename='Back Facial with Microdermabrasion' charges='$120 (60 min)'
                                            bgclr={skincarebgColor} txtclr='dark' minht='200px'
                                            onclickhandler={redirectToSquare}
                                            description='A back facial is a treatment that consists of deep cleansing, exfoliation (with the help of microdermabrasion) and extractions. It is performed on your back. In a back facial, I will use creams for massage and  steam to exfoliate and clean your skin before extracting oil and debris. The treatment ends with a detoxifying mask for hydration.'>

                                        </MyServices>
                                    </Col>
                                </Col>
                            </Row> */}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><b>Skin Booster Services</b></Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col>
                                    <MyServices servicename='Casmara Mask' charges='$40' bgclr={skinboosterbgColor} txtclr='light' minht='200px'
                                        onclickhandler={redirectToSquare}
                                        description='Excellent add-on for your facial. We have different types of masks for every all skin types.'>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='LED light therapy' charges='$20' bgclr={skinboosterbgColor} txtclr='light' minht='200px'
                                        onclickhandler={redirectToSquare}
                                        description='Boost your facial 100% excellent results for wrinkles and acne prone skin'>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Black head and Melina Extraction' charges='$40' bgclr={skinboosterbgColor} txtclr='light' minht='200px'
                                        onclickhandler={redirectToSquare}
                                        description='Get rid of blackheads and get excellent results for your skin.'>
                                    </MyServices>
                                </Col>
                            </Row>
                            <Row>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><b>Eyelash & Eyebrow Tint</b></Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col>
                                    <MyServices servicename='Eyebrow tinting' charges='$20 (20 min)' bgclr={eyeLashbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Eyebrow lamination & Tint' charges='$40 (30 min)' bgclr={eyeLashbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Eyelash Tinting' charges='$25 (25 min)' bgclr={eyeLashbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Eyelash Lift & Tint' charges='$80 (45 min)' bgclr={eyeLashbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><b>Threading</b></Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col>
                                    <MyServices servicename='Eyebrow' charges='$15' bgclr={threadingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Lips' charges='$10' bgclr={threadingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Chin' charges='$10' bgclr={threadingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Sideburn' charges='$10' bgclr={threadingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><b>Waxing</b></Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col>
                                    <MyServices servicename='Eyebrow' charges='$15 (30 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Full Face' charges='$40 (45 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Lips' charges='$10 (15 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Chin' charges='$10 (10 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Underarms' charges='$20 (30 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Full Arms' charges='$45 (45 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Half arms' charges='$30 (30 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Full Legs' charges='$70 (60 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Half Legs' charges='$45 (45 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Chest' charges='$50 (60 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Stomach' charges='$20 (30 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Back' charges='$45 (45 min)' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                {/* <Col>
                                    <MyServices servicename='Women’s Bikini Brazilian' charges='$50' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col> */}
                                <Col>
                                    <MyServices servicename='Full' charges='$40' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                                <Col>
                                    <MyServices servicename='Line' charges='$30' bgclr={waxingbgColor} txtclr='dark' minht='150px'
                                        onclickhandler={redirectToSquare} description=''>
                                    </MyServices>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>

            <Footer />
        </Container>
    );
}

export default ServicesPage
