import React from 'react';
import PropTypes from 'prop-types';
import styles from './Credits.module.css';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Image Credits</Popover.Header>
    <Popover.Body>
      {/* Independence Day image */}
      {/* <a target="_blank" href='https://www.freepik.com/vectors/usa-background'>Usa background vector created by starline - www.freepik.com</a> */}
      {/* Back to school mom */}
      <a target="_blank" href='https://www.freepik.com/photos/beauty-girl'>Beauty girl photo created by benzoix - www.freepik.com</a>
      <a target="_blank" href='https://www.freepik.com/photos/woman'>Woman photo created by Racool_studio - www.freepik.com</a>
      <a target="_blank" href="https://www.freepik.com/photos/woman">Woman photo created by pressfoto - www.freepik.com </a>
      <a target="_blank" href='https://www.freepik.com/photos/banner'>Banner photo created by master1305 - www.freepik.com</a>
      <a target="_blank" href='https://www.freepik.com/photos/woman'>Woman photo created by drobotdean - www.freepik.com</a>
      <a target="_blank" href='https://www.freepik.com/photos/woman'>Woman photo created by cookie_studio - www.freepik.com</a>
      <a target="_blank" href='https://www.freepik.com/vectors/banner'>Banner vector created by zaie - www.freepik.com</a>
      <a target="_blank" href='https://www.freepik.com/photos/frame'>Frame photo created by valeria_aksakova - www.freepik.com</a> 
      <a target="_blank" href='https://www.freepik.com/photos/smiling-girl'>Smiling girl photo created by wayhomestudio - www.freepik.com</a>
      <a target="_blank" href='https://www.freepik.com/photos/woman'>Woman photo created by cookie_studio - www.freepik.com</a>
      <a target="_blank" href='https://www.freepik.com/vectors/background'>Background vector created by articular - www.freepik.com</a>
      {/* <a target="_blank"  href='https://www.freepik.com/vectors/flowers-mothers-day'>Flowers mothers day vector created by freepik - www.freepik.com</a> */}
      {/* <a target="_blank" href='https://www.freepik.com/photos/optimistic'>Optimistic photo created by wayhomestudio - www.freepik.com</a> */}
      {/* <a target="_blank" href="https://www.freepik.com/free-photo/three-cute-halloween-pumpkins-autumn-park_5915421.htm#query=Pumpkins&position=2&from_view=search">Image by senivpetro</a> on Freepik */}
      <a target="_blank" href="https://www.freepik.com/free-vector/pyrotechnics-fireworks-background-with-animation-black_13705530.htm#query=crackers&position=45&from_view=search">Image by macrovector</a> on Freepik
    </Popover.Body>
  </Popover>
);

const Credits = () => (
  <div>
    <OverlayTrigger trigger="click" placement="top" overlay={popover}>
      <a className={styles.imgcrdlnk} href='#'>Image Credits</a>
    </OverlayTrigger>
  </div>
);

Credits.propTypes = {};

Credits.defaultProps = {};

export default Credits;
