import React from 'react';
import {
    Row,
    Col,
    Container,
    Accordion,
    Image,
    Button
} from 'react-bootstrap'
import Header from '../../components/Header/Header';
import Carouselimages from '../../components/Carouselimages/Carouselimages';
import Footer from '../../components/Footer/Footer';
import './HomePage-Style.css';
import Aboutus from '../../components/Aboutus/Aboutus';
import Specials from '../../components/Specials/Specials';
import HomeMiddle from '../../components/HomeMiddle/HomeMiddle';
import TestimonialComp from '../../components/TestimonialComp/TestimonialComp';
import Announcement from '../../components/Announcement/Announcement';

const HomePage = () => {
    return (
        <Container fluid>
            <div className="App">
                <Header></Header>
                <br />
                <div className='carouselContainer'>
                    <Row className='Banner'>
                        <Col>
                            <h1 style={{ fontFamily: 'Papyrus', marginTop: '2vw', padding: '2vw', 'verticalAlign': 'middle' }}>Welcome To SkinO2 Esthetics</h1>
                            {/* <Announcement></Announcement> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Carouselimages></Carouselimages>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ alignContent: 'center' }}>
                            <HomeMiddle />
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            <Specials />
                        </Col>
                    </Row> */}
                    <br />
                    <Row>
                        <Col style={{ alignContent: 'center' }}>
                            <div style={{ fontFamily: 'Papyrus' }}>
                                <h2>We are the talk of the town!!</h2>
                                <h4>Here is what our clients think about us!!</h4>
                            </div>
                            <hr />
                            <TestimonialComp />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={{ alignContent: 'center' }}>
                            <Aboutus></Aboutus>
                        </Col>
                    </Row>

                    <Row>
                        <Col><Footer /></Col>
                    </Row>

                </div>

            </div>
        </Container>
    );
}

export default HomePage