import React from 'react';
import PropTypes from 'prop-types';
import styles from './HomeCard.module.css';
import {
  Card,
  Button,
  Nav

} from 'react-bootstrap';


const HomeCard = (props) => (
  <Card style={{ width: '18rem', minHeight: '30vw', margin:'1rem' }}>
    <Card.Img variant="top" src={props.image} />
    <Card.Body style={{ backgroundColor: props.bgColor }}>
      <Card.Title style={{ fontFamily: 'Papyrus', fontWeight: 'bold' }}>{props.header}</Card.Title>
      <Card.Text>
        {props.body}
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <Nav.Link href="/services">
        <Button variant="dark">Explore Services</Button>
      </Nav.Link>
    </Card.Footer>
  </Card>
);

HomeCard.propTypes = {};

HomeCard.defaultProps = {};

export default HomeCard;
