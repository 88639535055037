import Lottie from 'react-lottie';
import animationData from '../../Lotties/contact-us-1.json';
import React from 'react'
import {
  Container,
  Row,
  Col,
  Alert,
  Image
} from 'react-bootstrap'
//import GoogleMapComp from '../GoogleMapComp/GoogleMapComp';
import BusinessHoursComp from '../BusinessHoursComp/BusinessHoursComp';
import address from '../../images/address-1.png';
import insta from '../../images/SkinO2-Insta-v2.png';

const ContactUs = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Lottie options={defaultOptions}
            height={400}
            width={400} />
        </Col>
        <Col>
          <br />
          {/* <GoogleMapComp /> */}
          <Image style={{ width: '100%' }} src={address}></Image>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <Col style={{textAlign: 'center'}}>
            <Alert>
              {/* <Alert.Heading>Address: 9040 Tryfon Boulevard, Suite A 101, Trinity, FL 34655</Alert.Heading> */}
              <Alert.Heading>Address: 3188 Oliver Creek Drive, Odessa 33556</Alert.Heading>
              <p>
                Phone : <a href={"tel:813 613-2424"}>813 613-2424</a>
              </p>
              <hr />
              <p className="mb-0">
                Email : <a href={"mailto:mayurijoshikulkarni@gmail.com"} target="_blank"> SkinO2 Esthetics </a>
              </p>
              <hr />
              <p>
                Connect with us on Instagram!
                <div>
                  <Image style={{height: '200px'}} src={insta}></Image>
                </div>
              </p>
            </Alert>
         
        </Col>
        {/* <Col style={{ height: '20%', textAlign: 'center' }}>
          <Image src={insta}></Image>
        </Col> */}

      </Row>
      <Row>
        <BusinessHoursComp />
      </Row>

    </Container>
  );

}

export default ContactUs