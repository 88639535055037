import React from 'react';
import PropTypes from 'prop-types';
import styles from './TestimonialComp.module.css';
import {
  Card,
  Carousel,
  Row,
  Col,
  CarouselItem,
  Container,
  Image
} from 'react-bootstrap';

import manImage from '../../images/man.png';
import womanImage from '../../images/woman.png'
import Lottie from 'react-lottie';
import animationData from '../../Lotties/discussions.json';


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const TestimonialComp = (props) => (


  <Container fluid>
    <Row>
    <Col style={{ margin: 'auto' }}>
        <Lottie options={defaultOptions}
          width={300}
          height={300}
        />
      </Col>
      <Col style={{ textAlign: 'center' }}>
        <Carousel>
          <CarouselItem>
            <Card style={{ width: '100%' }}>
              <Card.Body className={styles.CardBodyBg} style={{ padding: '10vh' }}>
                <Image src={womanImage} className={styles.AvatarImage}></Image>
                <hr />
                <Card.Title><h2><i>Melanie</i></h2></Card.Title>
                <Card.Text>
                  <hr />
                  I have had the pleasure of having a number of facials from Mayuri. I can only say that I wish every friend I have could experience the beauty of her graciousness, expertise and kindness. l have very sensitive skin and tend to breakout after any treatment.  However, I've never had that issue since coming to Mayuri. Her services are worth every penny.
                </Card.Text>
              </Card.Body>
            </Card>
          </CarouselItem>
          <CarouselItem>
            <Card style={{ width: '100%' }}>
              <Card.Body className={styles.CardBodyBg} style={{ padding: '10vh' }}>
                <Image src={manImage} className={styles.AvatarImage}></Image>
                <hr />
                <Card.Title><h2><i>Mohit</i></h2></Card.Title>
                <Card.Text>
                  <hr />
                  Mayuri is very professional. She cares about every single detail of what you like and what would be best for your skin. She seemed to be very knowledgeable and provided me with deep insight into which product is good for my skin before starting my facial. I highly recommend her. You will come out shinning with SkinO2 Esthetics.
                </Card.Text>
              </Card.Body>
            </Card>
          </CarouselItem>
          <CarouselItem>
            <Card style={{ width: '100%' }}>
              <Card.Body className={styles.CardBodyBg} style={{ padding: '10vh' }}>
                <Image src={womanImage} className={styles.AvatarImage}></Image>
                <hr />
                <Card.Title><h2><i>Inna</i></h2></Card.Title>
                <Card.Text>
                  <hr />
                  Mayuri is a very kind and gentle esthetician thar creates a very relaxing atmosphere for her clients. I see great results after every facial I receive from her. I recommend her to everyone!
                </Card.Text>
              </Card.Body>
            </Card>
          </CarouselItem>
          <CarouselItem>
            <Card style={{ width: '100%' }}>
              <Card.Body className={styles.CardBodyBg} style={{ padding: '10vh' }}>
                <Image src={womanImage} className={styles.AvatarImage}></Image>
                <hr />
                <Card.Title><h2><i>Jyotika</i></h2></Card.Title>
                <Card.Text>
                  <hr />
                  I have known Mayuri for long time. I am one of her regular customer as I know I am in good hands. She cares about her clients and is very professional. She knows what is best for your skin as she listen to you and understand your esthetic goals. She is the best in what she does and I highly recommend her.
                </Card.Text>
              </Card.Body>
            </Card>
          </CarouselItem>
          <CarouselItem>
            <Card style={{ width: '100%' }}>
              <Card.Body className={styles.CardBodyBg} style={{ padding: '10vh' }}>
                <Image src={womanImage} className={styles.AvatarImage}></Image>
                <hr />
                <Card.Title><h2><i>Zorielis</i></h2></Card.Title>
                <Card.Text>
                  <hr />
                  Mayuri is a very professional person. Her knowledge and passion for service is incredibly upstanding. I’m happy of having her as my esthetician because she always receives you with a smile and with an amazing treatments.
                </Card.Text>
              </Card.Body>
            </Card>
          </CarouselItem>
        </Carousel>

      </Col>

    </Row>
  </Container>


);

TestimonialComp.propTypes = {};

TestimonialComp.defaultProps = {};

export default TestimonialComp;
