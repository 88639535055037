import React from 'react';
import PropTypes from 'prop-types';
import styles from './BckToSchoolComp.module.css';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Nav, Alert
} from 'react-bootstrap';

import mom from '../../images/mom.png';


const redirectHandler = (data) => {
  window.open('https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVJ/sJqo5GNbuORPk3RPnhYWfJ6cwVM2PXUtnzxDQBXPCIYP+okZs6vUaY8KPzPtZLsPXLvFB/NXBaqgI0gqZwmQPPMyUS3KAoN+IzLTQHUmCGJx/i5KSKt3NtplhhbgWu7z+wAlCI3FSDNG5G5Y5Lowa2eipmcz89lSGgPkhRuC7pqyvBMcS93FVAfDY+FN+IAyOTxr7cXuu8WRRDkaBb3tY8LwM9n2qv4ByiTdJxKS0qGUXctuL/OFDemRgrLpLoSZf9lZXdPyLsEJLzPNKdD4deQN0HR9Gwdb0ufZv/wUXcdEyJAvAwiv6NqSecJpiRA==');
}


const BckToSchoolComp = () => (
  <div className={styles.BckToSchoolComp}>
    <Container fluid>
      <Alert className={styles.BckToSchoolCompGrad}>
        <Row className={styles.rowClass} style={{ fontFamily: 'Copperplate', color: 'black' }}>
          <Col>
            <div>
              <h1 style={{ paddingTop: '2vh' }}>KIDS IN SCHOOL?</h1>
              <h2>PAMPER YOURSELF WITH....</h2>
              <hr />
            </div>
            <div style={{ top: '-20vh', fontFamily: 'Papyrus', margin: '2vw' }}>
              <Image src={mom} style={{ width: '80%' }}></Image>
            </div>
            <div>
              <h3>Custom Facial</h3>
              <h3><p><s>$115</s></p></h3>
              <h3><p><i>NOW $100</i></p></h3>
              <hr />
            </div>
            <div>
              <h3>Basic Treatment Facial</h3>
              <h3><p><s>$70</s></p></h3>
              <h3><p><i>NOW $60</i></p></h3>
              
            </div>
            <div>** Valid till Aug 31, 2022</div>
            <br />
            <Button style={{ width: '100%' }} onClick={redirectHandler.bind(this)} variant="dark">Book My Appointment</Button>
            <hr />
          </Col>
        </Row>
      </Alert>
    </Container>
  </div>
);

BckToSchoolComp.propTypes = {};

BckToSchoolComp.defaultProps = {};

export default BckToSchoolComp;
