import React from 'react';
import PropTypes from 'prop-types';
import styles from './IndependenceDayComp.module.css';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Nav
} from 'react-bootstrap';

import indDayImage from '../../images/IndependenceDay.jpg';
const redirectHandler = (data) => {
  window.open('https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVJ/sJqo5GNbuORPk3RPnhYWfJ6cwVM2PXUtnzxDQBXPCIYP+okZs6vUaY8KPzPtZLsPXLvFB/NXBaqgI0gqZwmQPPMyUS3KAoN+IzLTQHUmCGJx/i5KSKt3NtplhhbgWu7z+wAlCI3FSDNG5G5Y5Lowa2eipmcz89lSGgPkhRuC7pqyvBMcS93FVAfDY+FN+IAyOTxr7cXuu8WRRDkaBb3tY8LwM9n2qv4ByiTdJxKS0qGUXctuL/OFDemRgrLpLoSZf9lZXdPyLsEJLzPNKdD4deQN0HR9Gwdb0ufZv/wUXcdEyJAvAwiv6NqSecJpiRA==');
}

const IndependenceDayComp = () => (
  <div className={styles.IndependenceDayComp}>
    <Container fluid>
      <Row className={styles.rowClass}>
        <Col className={styles.colClass}>
          <div style={{ top: '-20vh', fontFamily: 'Papyrus', margin: '2vw' }}>
            <Image src={indDayImage} style={{ width: '100%' }}></Image>
          </div>

          <div className={styles.customContent}>
            <h1 style={{ paddingTop: '2vh' }}>Freedom Summer Special</h1>
            <hr />
          </div>
          <div>
            <h2>15% discount to all our first time clients!</h2>
            <p>
              Gentle Enzyme and Vital-C facial is perfect for treating
              summer skin. It helps to reduce dark spots & aging signs. 
              Enjoy Face, Neck and Décolleté massage with a free add-on of Hand massage
            </p>
            <p>
              Try it out today!!
            </p>
          </div>
          <Button style={{ width: '100%' }} onClick={redirectHandler.bind(this)} variant="light">Book My Appointment</Button>
          <hr />
        </Col>
      </Row>
    </Container>
  </div>
);

IndependenceDayComp.propTypes = {};

IndependenceDayComp.defaultProps = {};

export default IndependenceDayComp;
