import React from 'react';
import PropTypes from 'prop-types';
import styles from './WomansDayOffer.module.css';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Nav
} from 'react-bootstrap';
import woman from '../../images/womansday-7.png'
// import woman from '../../images/womansday-3.jpg';

const redirectHandler = (data) => {
  window.open('https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVJ/sJqo5GNbuORPk3RPnhYWfJ6cwVM2PXUtnzxDQBXPCIYP+okZs6vUaY8KPzPtZLsPXLvFB/NXBaqgI0gqZwmQPPMyUS3KAoN+IzLTQHUmCGJx/i5KSKt3NtplhhbgWu7z+wAlCI3FSDNG5G5Y5Lowa2eipmcz89lSGgPkhRuC7pqyvBMcS93FVAfDY+FN+IAyOTxr7cXuu8WRRDkaBb3tY8LwM9n2qv4ByiTdJxKS0qGUXctuL/OFDemRgrLpLoSZf9lZXdPyLsEJLzPNKdD4deQN0HR9Gwdb0ufZv/wUXcdEyJAvAwiv6NqSecJpiRA==');
}

const WomansDayOffer = () => (
  <div className={styles.Specials}>
    <Container fluid>
      {/* <Image src={woman} style={{ width: '100%' }}></Image>
      <div>
        <Button style={{ width: '100%' }} onClick={redirectHandler.bind(this)} variant="dark">Explore Services</Button>
      </div> */}
      <Row className={styles.rowClass}>
        <Col className={styles.colClass}>
          <div style={{ top: '-20vh', fontFamily: 'Papyrus', margin: '2vw' }}>
            <h1>Celebrating Women's Month</h1><hr />
            <Image src={woman} style={{ width: '90%' }}></Image>
          </div>

          <div className={styles.specialContainer}>
            <div className={styles.Title}>ME TIME ($130)</div>
            <div className={styles.content}>Signature facial with relaxing scalp massage</div>
          </div>

          <div className={styles.specialContainer}>
            <div className={styles.Title}>YOUTH REVIVE ($140)</div>
            <div className={styles.content}>Anti Aging facial with casmara mask</div>
          </div>

          <div className={styles.specialContainer}>
            <div className={styles.Title}>GLOW UP (20% OFF)</div>
            <div className={styles.content}>Purchase TWO dermaplanning facials and save</div>
          </div>

          <div className={styles.specialContainer}>
            <div className={styles.Title}>NATURAL BLISS ($140)</div>
            <div className={styles.content}>
              <div>Vital C Facial with back scrub treatment</div>
              <div><b>Take home free Vital C Kit!!</b></div>
            </div>
          </div>

          <Button style={{width:'100%'}} onClick={redirectHandler.bind(this)}  variant="dark">Explore Services</Button>
          <hr />
        </Col>
      </Row>
    </Container>
  </div>

);

WomansDayOffer.propTypes = {};

WomansDayOffer.defaultProps = {};

export default WomansDayOffer;
