import React from 'react';
import Header from '../../components/Header/Header';
import Lottie from 'react-lottie';
import animationData from '../../Lotties/booking.json';

import {
    Container,
    Row, 
    Col
} from 'react-bootstrap'
import Footer from '../../components/Footer/Footer';

const BookingPage = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Container fluid>
            <div className="App" className="container">

                <Header></Header>
                <Row>
                    <Col>
                        <Lottie options={defaultOptions}
                            height={400}
                            width={400} />
                    </Col>
                    <Col>
                        <br />
                        <h1>Stay Tuned!!</h1>
                        <i>We are just a few days away from our big launch</i>
                        {/* <iframe style={{width:'500px', height: '500px'}} src="https://www.serenitysheermassage.com/"></iframe> */}
                        {/* <iframe style={{width:'500px', height: '500px'}} src="https://squareup.com/appointments/book/i23tjqe21kip90/DF6TAEG2CP6WE/services"></iframe> */}

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Footer />
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default BookingPage