import React from 'react';
import PropTypes from 'prop-types';
import styles from './GoogleMapComp.module.css';
//import GoogleMapReact from 'google-map-react';
import Iframe from 'react-iframe'

// const address = {
//   center: {
//     lat: 59.95,
//     lng: 30.33
//   },
//   zoom: 11
// };

const GoogleMapComp = () => (
  <div className={styles.GoogleMapComp}>
    {/* <iframe src="<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14060.057183724472!2d-82.664781!3d28.178243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7fe89a888c308d92!2sSerenity%20Sheer%20Massage!5e0!3m2!1sen!2sus!4v1643257253141!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}


    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14060.057183724472!2d-82.664781!3d28.178243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7fe89a888c308d92!2sSerenity%20Sheer%20Massage!5e0!3m2!1sen!2sus!4v1643257253141!5m2!1sen!2sus"
        width="100%"
        height="auto"
        id="sparklemap"
        display="initial"
        position="relative"/>

   {/* <div style={{ height: '100vh', width: '100%' }}>
    <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCJBwwSJpP7eWTtadZqScvVQFhvKiwxGQ0' }}
          defaultCenter={address.center}
          defaultZoom={address.zoom}
    />
   </div> */}
  </div>
);

GoogleMapComp.propTypes = {};

GoogleMapComp.defaultProps = {};

export default GoogleMapComp;
