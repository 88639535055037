import React from 'react';
import PropTypes from 'prop-types';
import styles from './HomeMiddle.module.css';
import woman from '../../images/woman-1.jpg';
import woman2 from '../../images/woman-2.jpg';
import woman3 from '../../images/woman-3.jpg';

import {
  Container,
  Row,
  Col,
  Image,
  Button
} from 'react-bootstrap';
import HomeCard from '../HomeCard/HomeCard';
const HomeMiddle = () => (
  <Container>
    <br />
    <Row>
      <Col>
        <h1 style={{fontFamily : 'Papyrus'}}>
          The place that leaves you feeling great
        </h1>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <HomeCard image={woman} header='Recover. Rejuvenate. Revive!'
          body='Progressive styling in a nurturing environment.' bgColor='#ce9fc9' />
      </Col>
      <Col>
        <HomeCard image={woman2} header='Be welcomed. Be pampered. Be yourself.'
          body='Luxury salon where you will feel unique and special.' bgColor='#9e96b9' />
      </Col>
      <Col>
        <HomeCard image={woman3} header='Everyone is beautiful, we just make it obvious!'
          body='Relaxation. No longer beyond your budget. Revitalize your beauty, Revitalize your soul.' bgColor='#dc7c71' />
      </Col>
    </Row>
  </Container>

);

HomeMiddle.propTypes = {};

HomeMiddle.defaultProps = {};

export default HomeMiddle;
