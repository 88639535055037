import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import AboutUsPage from './Pages/AboutUsPage/AboutUsPage';
import HomePage from './Pages/HomePage/HomePage';
import ServicesPage from './Pages/ServicesPage/ServicesPage';
import ContactUsPage from './Pages/ContactUsPage/ContactUs'
import BookingPage from './Pages/SquareBookingPage/BookingPage';
import ReviewPage from './Pages/ReviewPage/ReviewPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<HomePage />}></Route>
        <Route path="/aboutus" exact element={<AboutUsPage />}></Route>
        <Route path="/services" exact element={<ServicesPage />}></Route>
        <Route path="/contactus" exact element={<ContactUsPage />}></Route>
        <Route path="/booking" exact element={<BookingPage />}></Route>
        <Route path="/reviews" exact element={<ReviewPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
