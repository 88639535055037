import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.css';
import Credits from '../Credits/Credits';

const Footer = () => (
  <div>
    <div className={styles.Footer}>
      © SkinO2 Esthetics 2022.
      <div className={styles.imgcredits}>
        <Credits />
      </div>
    </div>

  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
