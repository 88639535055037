import React from 'react';
import PropTypes from 'prop-types';
import styles from './SpringSpecial.module.css';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Nav
} from 'react-bootstrap';
import spring from '../../images/Spring.jpg'

const redirectHandler = (data) => {
  window.open('https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVJ/sJqo5GNbuORPk3RPnhYWfJ6cwVM2PXUtnzxDQBXPCIYP+okZs6vUaY8KPzPtZLsPXLvFB/NXBaqgI0gqZwmQPPMyUS3KAoN+IzLTQHUmCGJx/i5KSKt3NtplhhbgWu7z+wAlCI3FSDNG5G5Y5Lowa2eipmcz89lSGgPkhRuC7pqyvBMcS93FVAfDY+FN+IAyOTxr7cXuu8WRRDkaBb3tY8LwM9n2qv4ByiTdJxKS0qGUXctuL/OFDemRgrLpLoSZf9lZXdPyLsEJLzPNKdD4deQN0HR9Gwdb0ufZv/wUXcdEyJAvAwiv6NqSecJpiRA==');
}

const SpringSpecial = () => (
  <div className={styles.SpringSpecial}>
     <Container fluid>
      <Row className={styles.rowClass}>
        <Col className={styles.colClass}>
          <div style={{ top: '-20vh', fontFamily: 'Papyrus', margin: '2vw' }}>
            {/* <h1>Celebrating Spring 2022</h1><hr /> */}
            <Image src={spring} style={{ width: '90%' }}></Image>
          </div>

          <div className={styles.customContent}>
            Rejuvenating and Relaxing 
            <p><b>April Spa</b></p>
            <hr />
          </div>  
          <div>
            <p>Our customized Facial is the ultimate skincare.</p>
            <p>Cleanse exfoliate and treat your skin according to your specific needs</p>
            <hr />
          </div>
          <div className={styles.specialContainer}>
            <div className={styles.Title}>75 MIN. FACIAL WITH MASSAGE ($85)</div>
            <div className={styles.content}>Facial with hand + foot or scalp massage (Lavendar Fragrance) </div>
          </div>

          <div className={styles.specialContainer}>
            <div className={styles.Title}>105 MIN. FACIAL WITH MASSAGE ($125)</div>
            <div className={styles.content}>Facial with hand + foot or scalp massage (Lavendar Fragrance) </div>
          </div>

         
          <Button style={{width:'100%'}} onClick={redirectHandler.bind(this)}  variant="dark">Explore Services</Button>
          <hr />
        </Col>
      </Row>
    </Container>
  </div>
);

SpringSpecial.propTypes = {};

SpringSpecial.defaultProps = {};

export default SpringSpecial;
